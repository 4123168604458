import { graphql, HeadFC, navigate, Script } from 'gatsby';
import parse from 'html-react-parser';
import { throttle, uniqueId } from 'lodash';
import React, { lazy, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../components/common/Breadcrumb';
import ImageGallery from '../../components/common/ImageGallery';
import PageLoader from '../../components/common/Loader/PageLoader';
import ProductDetailsLoader from '../../components/common/Loader/ProductDetailsLoader';
import ProductLongLoader from '../../components/common/Loader/ProductLongLoader';
import ProductDetailsCounter from '../../components/common/ProductDetailsCounter';
import TruncatedHtml from '../../components/common/TruncatedHtml/TruncatedHtml';
import Layout from '../../components/layout/Layout';
// import ProductCarousel from '../../components/Products/ProductCarousel';
const ProductCarousel = lazy(() => import('../../components/Products/ProductCarousel'));

import Varient from '../../components/Products/Varient';
import 'react-multi-carousel/lib/styles.css';
import StarReview from '../../components/Review/StarReview';
import Seo from '../../components/Seo';
import { AppDispatch, rootReducer, RootState } from '../../store';
import { checkAndAddToCart, slideToggle } from '../../store/cartSlice';
import { CartItem, Crumb, Product as ProductType } from '../../types';
import './ProDetails.css';
import useJWTChecker from '../../utils/Custom-hook/useJWTChecker';
import { calculatePriceRange, capitalizeFirstLetter } from '../../utils/helper';
import visa from '../../../src/assets/images/visa-card.png';
import vimeo from '../../../src/assets/images/vimeo.png';
import masterCard from '../../../src/assets/images/master-card.png';
import { toast } from 'react-toastify';
import { useLocation } from '@reach/router';
import { Lock, PPolicy, SeaSame, Shipping } from '../../components/common/Svg';
import { updateUserCart } from '../../services/woocommerceServices/Customer';
import { sendGAEvent } from '../../utils/analytics';
import { GA4_Events } from '../../utils/Constants/GA4_Events';
import useIsMobile from '../../utils/Custom-hook/useIsMobile';
import ProductDescription from '../../components/common/ProductDescription';
import LazyLoad from '../../components/common/LazyLoad';
import useClearDataLayer from '../../utils/Custom-hook/useClearDataLayer';
import { MobilePro } from '../../components/Products/MobilePro';
import { DesktopPro } from '../../components/Products/DesktopPro';

// Memoize components that don't need frequent re-renders
const MemoizedStarReview = memo(StarReview);
const MemoizedImageGallery = memo(ImageGallery);
const MemoizedProductCarousel = memo(ProductCarousel);
const MemoizedBreadcrumb = memo(Breadcrumb);
const MemoizedTruncatedHtml = memo(TruncatedHtml);
const MemoizedProductDescription = memo(ProductDescription);

declare global {
  interface Window {
    gtag: any;
  }
}

export default function Product(props: any) {
// useClearDataLayer();
  const location = useLocation();
  const isMobile = useIsMobile();
  const { data } = props;
  const { productAdditionalData } = data;
  const secret = process.env.GATSBY_YOTPO_APPKEY;

  const { userId } = useJWTChecker();
  const cartItems = useSelector((state: any) => state.cart.items);
  const cart = useSelector((state: any) => state.cart);
  const dispatch = useDispatch<AppDispatch>();

  // States
  const [crumbs, setCrumbs] = useState<any[]>([]);
  const [image, setImage] = useState('');
  const [product, setProduct] = useState<any>(data.data);
  const [productPrice, setProductPrice] = useState('');
  const [productPriceLimit, setProductPriceLimit] = useState('');
  const [variants, setVariants] = useState<any[]>([]);
  const [relatedProduct, setRelatedProduct] = useState<any[]>([]);
  const [selectedVariant, setSelectedVariant] = useState<any>({});
  const [groupedProduct, setGroupedProduct] = useState<any[]>([]);
  const [priceRange, setPriceRange] = useState<any>({});
  const [seoInfo, setSeoInfo] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState<number | string>(1);
  const varButtonRef = useRef<HTMLDivElement>(null);
  const productDetailsSecRef = useRef<HTMLDivElement>(null);
  const [block, setBlock] = useState(false);
  const [option, setOption] = useState<any[]>([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [allVariant, setAllVariants] = useState<any>();
  const [additionalData, setAdditionalData] = useState<any>(null);
  const [selectedOptions, setSelectedOptions] = useState<any>({});
  const [selectedProductPrice, setSelectedProductPrice] = useState<any>({});
  const [allImages, setAllImages] = useState<any>(null);
  const [videoDetails, setVideoDetails] = useState<any>(false);

  // Define fixed dimensions to prevent layout shift
  const PRODUCT_IMAGE_DIMENSIONS = useMemo(() => ({ width: 500, height: 500 }), []);
  const THUMBNAIL_DIMENSIONS = useMemo(() => ({ width: 50, height: 50 }), []);

  // Helper: Add video image to gallery if applicable
  const addVideoToImages = useCallback((images: any[], videoGallery: any) => {
    let videoInfo = null;
    if (videoGallery) {
      const videoID = getYouTubeVideoID(videoGallery.featuredYoutubeVideo);
      const hasVideo = images.some((el) => el?.type === 'video');
      if (videoID && !hasVideo) {
        const videoItem = {
          id: 18263,
          type: 'video',
          src: `https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`,
          url: videoGallery.featuredYoutubeVideo,
          name: 'video',
          alt: 'video',
        };
        images.push(videoItem);
        videoInfo = videoItem;
      }
    }
    return { images, videoInfo };
  }, []);

  // Helper: Update breadcrumbs based on location state or product data
  const updateBreadcrumbs = useCallback(() => {
    const stateData = props.location.state;
    if (stateData?.categoryName) {
      setCrumbs([
        {
          label: stateData.categoryName,
          link: `/product-category/${stateData.categorySlug}`,
        },
        { label: stateData.productName, link: null },
      ]);
    } else {
      setCrumbs([
        {
          label: product?.categories?.[0]?.name,
          link: `/product-category/${product?.categories?.[0]?.slug}`,
        },
        { label: product?.name, link: null },
      ]);
    }
  }, [props.location.state, product]);

  // Helper: Update Yotpo widgets for reviews
  const updateYotpoWidgets = useCallback(() => {
    if (window.yotpo) {
      window.yotpo.initialized = false;
      window.yotpo.clean();
      window.yotpo.widgets.forEach((widget: any) => {
        widget.settings.pid = product.id;
        widget.settings.main_widget_pid = product.id;
      });
      if (window['yotpo']) {
        window['yotpo'].initWidgets();
      }
    }
  }, [product]);

  // Track view event and update Yotpo widgets / breadcrumbs on product change
  useEffect(() => {
    sendGAEvent('view_item', [
      {
        item_name: product.name,
        item_id: product.id,
        price: parseFloat(Number(product.price).toFixed(2)),
        quantity: 1,
        item_category: product.categories?.[0]?.name,
      },
    ]);

    const timer = setTimeout(() => {
      if (window.yotpo) {
        window.yotpo.initialized = false;
        window.yotpo.clean();
        window.yotpo.widgets.forEach((widget: any) => {
          widget.settings.pid = product.id;
          widget.settings.main_widget_pid = product.id;
        });
        if (window['yotpo']) {
          window['yotpo'].initWidgets();
        }
      }
    }, 1000);

    updateBreadcrumbs();
    return () => {
      clearTimeout(timer);
      window.dataLayer = [];
    };
  }, [product, updateYotpoWidgets, updateBreadcrumbs]);

  // Remove unwanted query parameters
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('q')) {
      urlParams.delete('q');
      const newUrl = urlParams.toString()
        ? `${window.location.pathname}?${urlParams.toString()}`
        : window.location.pathname;
      window.history.replaceState({}, document.title, newUrl);
      console.log("The 'q' parameter has been removed from the URL.");
    }
  }, []);

  // Handle changes for variant select
  const handleVariantChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event.target.value;
    setSelectedOption(value);
    const variantData = await processProduct(allVariant, value);
    if (variantData?.length) {
      setSelectedVariant(variantData[0]);
      setProductPrice(
        variantData[0]?.sale_price || variantData[0]?.regular_price
      );
    }
    setVariants(variantData);
    const firstImage = variantData.map((p: any) => p.image)[0];
    let imagesData = [firstImage];
    if (videoDetails) {
      imagesData.push(videoDetails);
    }
    setAllImages(imagesData);
  };

  // Throttled scroll handler for sticky variant button
  useEffect(() => {
    const handleScroll = throttle(() => {
      const varButton = varButtonRef.current;
      if (varButton) {
        const { bottom } = varButton.getBoundingClientRect();
        if (bottom < window.innerHeight) {
          varButton.classList.add('sticky');
        } else {
          varButton.classList.remove('sticky');
        }
      }
    }, 100);

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleSelect = (value: any) => {
    setSelectedVariant(value);
    setProductPrice(value?.sale_price);
  };

  // Handle add to cart click with grouped or simple product logic
  const handleAddToCart = async (
    product: any,
    variant: any,
    quantity: number = 1
  ) => {
    setBlock(true);
    if (groupedProduct?.length > 0) {
      const mainItem: any = {
        id: uniqueId(),
        productId: product.id,
        name: product.name,
        slug: product.slug,
        price: Number(productPrice),
        thumbnail: product.images?.[0]?.src,
        variant,
        quantity: Number(inputValue) || quantity,
        categories: product.categories,
        linkedProducts: [],
        isDiscounted: productAdditionalData.originProduct.isDiscounted,
      };

      const linkedProducts = await Promise.all(
        groupedProduct.map(async (grpProduct: any) => {
          const variantData = await groupProductProcess(
            grpProduct,
            selectedOptions
          );
          return {
            id: uniqueId(),
            productId: grpProduct.id,
            name: grpProduct.name,
            slug: grpProduct.slug,
            price: Number(variantData?.sale_price),
            thumbnail: grpProduct.images?.[0]?.src,
            variant: variantData,
            quantity: Number(inputValue) || quantity,
            categories: grpProduct.categories,
          };
        })
      );
      mainItem.linkedProducts = linkedProducts;
      await dispatch(
        checkAndAddToCart({
          item: mainItem,
          userId,
          quantity: Number(inputValue),
        })
      );
    } else {
      const cartItem = {
        id: uniqueId(),
        productId: product.id,
        name: product.name,
        slug: product.slug,
        price: Number(product.price),
        thumbnail: product.images?.[0]?.src,
        variant,
        quantity: Number(inputValue) || quantity,
        categories: product.categories,
        parentId: additionalData?.databaseId || null,
        isDiscounted: productAdditionalData.originProduct.isDiscounted,
      };

      const discountProductFlag = product.categories.some(
        (cat: any) => cat.slug === 'discounted-upsell-products'
      );
      if (discountProductFlag) {
        if (cart.items.some((item: any) => item.productId)) {
          await dispatch(
            checkAndAddToCart({
              item: cartItem,
              userId,
              quantity: Number(inputValue),
            })
          );
        } else {
          toast.error('Product can not be added.');
        }
      } else {
        await dispatch(
          checkAndAddToCart({
            item: cartItem,
            userId,
            quantity: Number(inputValue),
          })
        );
      }
    }

    sendGAEvent('add_to_cart', [
      {
        item_name: product.name,
        item_id: product.id,
        price: Number(product.price),
        quantity,
        item_category: product.categories?.[0]?.name,
      },
    ]);

    dispatch(slideToggle());
    setBlock(false);
  };

  // Update user cart whenever the cart or userId changes
  useEffect(() => {
    if (userId && cartItems?.length > 0) {
      updateUserCart(userId, cartItems);
    }
  }, [userId, cartItems]);

  // Handle attribute option change for grouped products
  const handleOptionChange = async (
    product: any,
    attributeName: string,
    value: any
  ) => {
    const newOptions = {
      [product.id]: {
        ...selectedOptions[product.id],
        [attributeName]: value,
      },
    };
    const variantData = await groupProductProcess(product, newOptions);
    setSelectedOptions((prev: any) => ({
      ...prev,
      [product.id]: {
        ...prev[product.id],
        [attributeName]: value,
      },
    }));
    setSelectedProductPrice((prev: any) => ({
      ...prev,
      [product.id]: { price: variantData?.sale_price || variantData?.price },
    }));
  };

  // Recalculate total product price when selected product prices update
  useEffect(() => {
    const totalPrice: any = Object.values(selectedProductPrice).reduce(
      (acc: any, curr: any) => acc + Number(curr.price || 0),
      0
    );
    setProductPrice(totalPrice.toFixed(2));
  }, [selectedProductPrice]);

  // Remove 'size' query parameter if present
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('size')) {
      navigate('');
    }
  }, []);

  const fetchProducts = useCallback(async () => {
    let initialImage: any[] = [];

    setImage(data.data?.images?.[0]?.src);

    if (data?.data?.grouped_products?.length > 0) {
      const linkedProducts = data.data.grouped_products;
      const productsWithVariants = await Promise.all(
        linkedProducts.map(async (grpProduct: any) => ({
          ...grpProduct,
          varient: grpProduct.varient,
        }))
      );

      setGroupedProduct(productsWithVariants);

      const initialSelectedOptions: any = {};
      const initialSelectedPrices: any = {};

      productsWithVariants.forEach((grpProduct: any) => {
        grpProduct.attributes.forEach((attr: any) => {
          initialSelectedOptions[grpProduct.id] = {
            ...initialSelectedOptions[grpProduct.id],
            [attr.name]: attr.options[0],
          };
        });
        const sortedVariants = grpProduct.varient.sort(
          (a: any, b: any) => Number(a.price) - Number(b.price)
        );
        initialSelectedPrices[grpProduct.id] = {
          price: sortedVariants[0]?.sale_price || sortedVariants[0]?.price,
        };
      });

      setSelectedOptions(initialSelectedOptions);
      setSelectedProductPrice(initialSelectedPrices);
      initialImage = [data.data.images[0]];
      const { images, videoInfo } = addVideoToImages(
        initialImage,
        productAdditionalData?.productVideoGallery
      );
      setVideoDetails(videoInfo);
      setAllImages(images);
    } else if (data?.data?.type === 'variable') {
      const variantsData = data.data.product_variations;
      const sortedVariants = variantsData.sort(
        (a: any, b: any) => Number(a.price) - Number(b.price)
      );
      setVariants(sortedVariants);
      setSelectedVariant(sortedVariants[0]);
      setProductPrice(sortedVariants[0]?.price);

      // -------------------
      // Price calculation
      // -------------------
      const minPrice = Number(sortedVariants[0].price).toFixed(2);
      const maxPrice = Number(sortedVariants[sortedVariants.length - 1].price).toFixed(2);
      setProductPriceLimit(`$${minPrice} - $${maxPrice}`);

      // -------------------------
      // Processing variant items
      // -------------------------
      setAllVariants(sortedVariants);
      const processedVariants = await processProduct(
        sortedVariants,
        data?.data?.attributes?.[0].options[0]
      );
      setSelectedVariant(processedVariants[0]);
      setProductPrice(processedVariants[0]?.sale_price);
      setVariants(processedVariants);

      // ------------------------------
      // Images and videos information
      // ------------------------------
      const firstImg = processedVariants.map((p: any) => p.image)[0];
      initialImage = [firstImg];
      const { images, videoInfo } = addVideoToImages(
        initialImage,
        productAdditionalData?.productVideoGallery
      );
      setVideoDetails(videoInfo);
      setAllImages(images);
    } else {
      const productDetails = data.data;
      const price = productDetails.sale_price || productDetails.price;
      setProductPrice(price);
      initialImage = productDetails.images;
      const { images, videoInfo } = addVideoToImages(
        initialImage,
        productAdditionalData?.productVideoGallery
      );
      setVideoDetails(videoInfo);
      setAllImages(images);
    }
    setLoading(false);
    setRelatedProduct(data.data.related_products);
  }, [props.location.state, data, productAdditionalData, addVideoToImages]);

  // Fetch and process product data on mount or location state change
  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const MobileView = () => (
    <div className="lg:hidden block">
      {loading ? (
        <div className="min-h-[130px] ">
          <ProductDetailsLoader />
        </div>
      ) : (
        <div className="w-full">
          <div className="w-full  flex flex-col">
            <h1 className="text-[28px] font-bold text-gray-900 min-h-[28px] ">
              {product?.name}
            </h1>
            {/* REVIEW START */}
            {/* <Review reviewData={4.5} /> */}

            <div className="my-2 yopto-rw min-h-[17px]">
              {product?.id ? (
                <MemoizedStarReview productId={product?.id} />
              ) : (
                'Loading..'
              )}
            </div>
            {/* REVIEW END */}
            {/* pricing */}
            {product?.price ? (
              <div className="mb-2 block">
                <span className="font-sofiaSemibold font-semiBold text-primary min-h-[20px] text-[20px]">
                  {groupedProduct?.length > 0
                    ? `$${productPrice}`
                    : product?.type === 'variable'
                      ? productPriceLimit
                      : `$${Number(product?.sale_price || product?.price).toFixed(
                        2
                      )}`}
                </span>
              </div>
            ) : (
              <></>
            )}

            <div
              className={`flex items-center lg:hidden justify-between min-h-[55px]`}
            >
              {/* selction start */}
              {product?.attributes?.[0]?.options.length > 1 ? (
                <div className="mt-[5px] mb-[10px] lg:hidden flex items-center justify-center">
                  <span className="mr-3 font-sofiaBold font-bold capitalize text-[13px] ">
                    {product?.attributes?.[0].name}
                    <span>:</span>
                  </span>
                  <select
                    value={selectedOption}
                    onChange={handleVariantChange}
                    id="sort-direction-mobile"
                    className="  bg-bg-grey h-[40px] rounded-[12px] text-[14px]  px-3 font-sofiaRegular font-regular focus:outline-none border w-[120px]"
                  >
                    {product?.attributes?.[0]?.options?.map((data: any) => {
                      return (
                        <option value={data} key={data}>
                          {data}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : (
                <></>
              )}
              {product?.attributes?.[0]?.options.length == 1 ? (
                <div className="mt-[5px] mb-[10px]  md:hidden block ">
                  <span className="mr-1 capitalize font-sofiaBold font-bold text-[16px]">
                    {product?.attributes?.[0].name}
                    <span>:</span>
                  </span>
                  <span className=" font-sofiaMedium text-[16px]">
                    {product?.attributes?.[0]?.options[0]}
                  </span>
                </div>
              ) : (
                <></>
              )}
              {/* selection end */}
              <div
                className={`w-1/2 md:hidden flex  md:pt-0 ${groupedProduct?.length > 0 ? 'justify-start ' : 'justify-end'
                  } `}
              >
                <ProductDetailsCounter
                  id="mobile"
                  initialQuantity={1}
                  setInputValue={setInputValue}
                  inputValue={inputValue}
                />
              </div>

              {/* counter end */}
            </div>
            {/* pricing end */}
            {/* short description */}
            {data.data?.short_description ? (
              <div
                className="mb-3 pro-det-text pro-det-text"
                dangerouslySetInnerHTML={{
                  __html: data.data?.short_description,
                }}
              />
            ) : (
              <div className="h-[100px]"></div>
            )}
          </div>
          {/* security-card */}
          <div className="md:hidden block border-t pt-3">
            <div className="bg-[#F5F5F5] md:px-[8px] px-[15px] py-[10px] lg:rounded-[36px] rounded-[14px] lg:mt-[15px] mt-[10px] ">
              <ul className="flex md:flex-row flex-col md:justify-center justify-start md:items-center gap-1 trans-details">
                <li>
                  <span className="w-30px">
                    <Shipping />
                  </span>
                  <span className="!w-full">Free shipping over $100</span>
                </li>
                <li>
                  <span className="w-30px">
                    <SeaSame />
                  </span>
                  <span className="!w-full">US compounding Pharmacy</span>
                </li>
                <li>
                  <span className="w-30px">
                    <PPolicy />
                  </span>
                  <span className="!w-full">Privacy Guarantee</span>
                </li>
              </ul>
            </div>
          </div>

          {/* security-card */}
          {/* bundle product */}
          {groupedProduct?.length > 0 ? (
            <div className="lg:hidden block bg-bg-grey mt-[30px] px-4 py-5 rounded-[12px]">
              {groupedProduct && groupedProduct?.length > 0 ? (
                groupedProduct.map((product) => {
                  return (
                    <div
                      key={product.id}
                      className="flex justify-between items-center cursor-pointer border-b last:border-b-0 pb-2 last:pb-0 mb-2 last:mb-0"
                    >
                      <div className="flex justify-start items-center gap-6 w-full">
                        <div
                          className="w-[70px] h-[70px] bg-white p-2 flex items-center rounded-[9px]"
                          onClick={() =>
                            navigate(`/products/${product.slug}`, {
                              state: {
                                ...props.location.state,
                                productName: product.name,
                                productId: product.id,
                              },
                            })
                          }
                        >
                          <img
                            src={product.images[0].src}
                            alt={product.images[0].alt}
                            width={50}
                            height={50}
                            className="rounded object-contain w-[50px] h-[50px] "
                          />
                        </div>
                        <div className="w-full">
                          <div
                            className="flex justify-between items-start w-full"
                            onClick={() =>
                              navigate(`/products/${product.slug}`, {
                                state: {
                                  ...props.location.state,
                                  productName: product.name,
                                  productId: product.id,
                                },
                              })
                            }
                          >
                            <p className="font-sofiaRegular font-semiBold  text-[15px]">
                              {product.name}
                            </p>
                            <p className="font-sofiaRegular font-semiBold  text-[15px]">
                              {selectedProductPrice[product.id]?.price
                                ? `$${Number(
                                  selectedProductPrice[product.id]?.price
                                ).toFixed(2)}`
                                : `$${Number(
                                  product.varient?.[0].sale_price
                                ).toFixed(2)}`}
                            </p>
                          </div>
                          <div className="flex flex-col justify-center mt-2  gap-1">
                            {/* Bundle product implementation */}
                            {product?.attributes?.map(
                              (attribute: any, index: any) => {
                                const unique_id = uniqueId();
                                return (
                                  <div
                                    className="flex justify-between items-center"
                                    key={index}
                                  >
                                    <span className="mr-3 text-[14px] font-sofiaRegular font-regular">
                                      {capitalizeFirstLetter(attribute?.name)}
                                      <span>:</span>
                                    </span>
                                    <select
                                      id={`${unique_id}-select`}
                                      value={
                                        selectedOptions?.[product.id]?.[
                                        attribute.name
                                        ] || ''
                                      }
                                      onChange={(e) => {
                                        handleOptionChange(
                                          product,
                                          attribute.name,
                                          e.target.value
                                        );
                                      }}
                                      className="w-[100px] bg-bg-grey h-[34px] text-[14px] rounded-[3px]  px-1 font-sofiaRegular font-regular focus:outline-none border "
                                    >
                                      {attribute?.options?.map(
                                        (option: string) => (
                                          <option value={option} key={option}>
                                            {option}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}

          {/* short description */}
          {/* Key features */}
          <div
            dangerouslySetInnerHTML={{
              __html: product?.acf?.key_points,
            }}
            className="key-text"
          ></div>
          {/* Key features */}
        </div>
      )}
    </div>
  );

  return (
    <Layout>
      {/* {seoInfo && <Seo info={seoInfo} />} */}
      <section className="px-[15px] pt-0 pb-6 md:px-[27px] lg:px-[88px] contain skdjskdf">
        <MemoizedBreadcrumb crumbs={crumbs} />
        <div className="mx-auto">
          <div className="flex flex-wrap">
            {/* Left Column: Image Gallery & Product Details */}
            <div className="slg:w-[55%] lg:w-[50%] w-full">
              <div className="w-full">
                <aside className="w-full lg:w-[95%]">
                  {!product?.images ? (
                    <div className="lg:min-h-[510px] min-h-[230px] flex justify-center items-center">
                      <PageLoader />
                    </div>
                  ) : (
                    <div className="w-full">
                      <MemoizedImageGallery images={allImages} />
                    </div>
                  )}
                </aside>

                {/* Mobile view for product details */}
                <MobileView />

                <div
                  className="md:mt-[50px] mt-[20px] md:pt-[40px] pt-[20px] w-full relative product-details-sec"
                  ref={productDetailsSecRef}
                >
                  <div className="w-[115%] absolute h-[14px] left-[-12%] top-0 bg-[#F6F6F6]" />
                  {loading ? (
                    <ProductLongLoader />
                  ) : (
                    <LazyLoad>
                      <section className="w-full">
                        <h2 className="font-sofiaSemibold font-semiBold mt-4 md:text-[40px] text-[28px] text-black">
                          Product details
                        </h2>
                        <LazyLoad>
                          {data.data?.description ? (
                            <ProductDescription
                              description={data.data.description}
                              initialHeight={300}
                              showMoreText="Show more details"
                              showLessText="Show less"
                            />
                          ) : (
                            <div className="mt-4 mb-6 min-h-[100px]"></div>
                          )}
                        </LazyLoad>

                        <div className="pro-review">
                          <div
                            className="yotpo yotpo-main-widget p-7"
                            data-product-id={product?.id}
                            data-price={product?.price}
                            data-currency="USD"
                            data-name={product?.name}
                            data-url={`https://spartanpeptides.com/products/${product.slug}/`}
                            data-image-url={product.images?.[0]?.src}
                          />
                        </div>
                      </section>
                    </LazyLoad>
                  )}
                </div>
              </div>
            </div>

            {/* Right Column: Product Info, Variants, and Add to Cart */}
            <main className="w-full slg:w-[45%] lg:w-[50%] px-5">
              {loading ? (
                <div className="md:block hidden">
                  <ProductDetailsLoader />
                </div>
              ) : (
                <>
                  <div className="w-full md:relative bg-white lg:pl-3 lg:block hidden">
                    <h1 className="text-[35px] mt-[-15px] font-sofiaSemibold font-semiBold text-black ml-[-4px] lg:block hidden min-h-[40px]">
                      {data.data?.name}
                    </h1>
                    <div className="lg:block my-1 yopto-rw min-h-[20px]">
                      {product?.id ? (
                        <MemoizedStarReview productId={product.id} />
                      ) : (
                        <div style={{ height: '20px', width: '100%' }} />
                      )}
                    </div>
                    {data.data.short_description ? (
                      <div className="min-h-[60px]">
                        <MemoizedTruncatedHtml html={data.data.short_description} />
                      </div>
                    ) : (
                      <div style={{ minHeight: '60px' }} />
                    )}
                  </div>

                    {isMobile?

                  <MobilePro props={{props, data, varButtonRef,  product, groupedProduct, productPrice , productPriceLimit,selectedOption,handleVariantChange, selectedProductPrice, selectedOptions,handleOptionChange,variants, selectedVariant, handleSelect, setInputValue, inputValue, block,handleAddToCart}}  />
                    :
                    <DesktopPro props={{props, data, varButtonRef,  product, groupedProduct, productPrice , productPriceLimit,selectedOption,handleVariantChange, selectedProductPrice, selectedOptions,handleOptionChange,variants, selectedVariant, handleSelect, setInputValue, inputValue, block,handleAddToCart}}/>
                    }
                 

                </>
              )}
            </main>
          </div>
        </div>
      </section>

      {/* RELATED PRODUCTS START */}
      <LazyLoad>
        {relatedProduct?.length > 0 ? (
          <div style={{ minHeight: '300px' }}> {/* Set a minimum height to prevent layout shifting */}
            <ProductCarousel
              isShowAddToCartBtn={false}
              productList={relatedProduct}
              title={'Related products'}
            />
          </div>
        ) : (
          <div style={{ minHeight: '300px' }} /> // Placeholder to maintain layout
        )}
      </LazyLoad>
      {/* RELATED PRODUCTS END */}
    </Layout>
  );
}

export const query = graphql`
  query ProductDetails($id: Int) {
    productAdditionalData: wpProduct(databaseId: { eq: $id }) {
      id
      productVideoGallery {
        featuredYoutubeVideo
      }
      productSchemaInformation {
        productSchema
      }
      originProduct {
        isDiscounted
        mainProduct {
          nodes {
            id
            slug
            status
            uri
            databaseId
          }
        }
      }
    }

    data: wcProducts(wordpress_id: { eq: $id }) {
      id: wordpress_id
      stock_status
      name
      description
      short_description
      price
      regular_price
      sale_price
      slug
      variations
      yoast_head
      categories {
        id
        name
        slug
      }
      images {
        id
        name
        alt
        src
      }
      type
      attributes {
        id
        name
        options
      }
      grouped_product_ids: grouped_products
      grouped_products: grouped_products_nodes {
        id: wordpress_id
        name
        price
        sale_price
        regular_price
        slug
        attributes {
          id
          name
          options
        }
        images {
          id
          name
          src
          alt
        }
        varient: product_variations {
          id
          name
          price
          sale_price
          regular_price
          type
          image {
            id
            name
            src
            alt
          }
          attributes {
            id
            name
            option
          }
          on_sale
        }
      }
      product_variations {
        id
        name
        price
        sale_price
        regular_price
        type
        image {
          id
          name
          src
          alt
        }
        attributes {
          id
          name
          option
        }
        on_sale
      }
      related_products {
        id
        name
        price
        sale_price
        regular_price
        slug
        images {
          id
          name
          src
          alt
        }
      }
    }
  }
`;

export const Head: HeadFC = (props: any) => {
  const location = useLocation();
  const secret = process.env.GATSBY_YOTPO_APPKEY;
  const category = props.data.data.categories[0];
  const parseHtml: any = parse(props.data.data.yoast_head);
  const schema: any =
    props.data.productAdditionalData.productSchemaInformation.productSchema;
  // const parseHtmlSchema: any = parse(schema);

  // console.log(schema, "====")
  // console.log(parseHtmlSchema, "parseHtmlSchema")

  const index =
    parseHtml &&
    parseHtml?.length > 0 &&
    parseHtml.findIndex(
      (item: any) => item?.props?.type === 'application/ld+json'
    );

  if (index > -1) {
    parseHtml.splice(index, 1);
  }

  return (
    <>
      {parseHtml}
      <Script src={`https://staticw2.yotpo.com/${secret}/widget.js`} defer />
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              name: 'Home',
              item: `${process.env.GATSBY_CLIENT_URI}`,
            },
            {
              '@type': 'ListItem',
              position: 2,
              name: `${category.name}`,
              item: `${process.env.GATSBY_CLIENT_URI}/product-category/${category.slug}/`,
            },
            {
              '@type': 'ListItem',
              position: 3,
              name: `${props.data.data.name}`,
              item: `${process.env.GATSBY_CLIENT_URI}/products/${props.data.data.slug}/`,
            },
          ],
        })}
      </script>
      <Seo Schema={schema} info={props.pageContext.yoast_head} />
    </>
  );
};

// Helper function: Extract YouTube video ID from URL
function getYouTubeVideoID(url: string): string | null {
  const regExp =
    /^.*(youtu\.be\/|v\/|\/u\/\w\/|embed\/|watch\?v=|\&v=|watch\?vi=)([^#\&\?]*).*/;
  const match = url?.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
}

async function processProduct(productData: any, attr: any) {
  const varient = productData;
  if (varient.length) {
    const matchAttr = varient.filter((data: any) => {
      if (data.attributes?.[0].option == attr) {
        return data;
      }
    });
    // console.log(matchAttr, "<<-- matchAttr");

    const sortedByVials = matchAttr.sort(
      (a: { attributes: any[] }, b: { attributes: any[] }) => {
        const aVials = a.attributes.find(
          (attr) => attr.name === 'vials'
        )?.option;
        const bVials = b.attributes.find(
          (attr) => attr.name === 'vials'
        )?.option;

        return parseInt(aVials) - parseInt(bVials);
      }
    );

    return sortedByVials;
  }
}

async function groupProductProcess(product: any, object: any) {
  let obj = object[product.id];
  const varient = product?.varient;
  if (varient.length) {
    // Function to check if an object's attributes match the desired attributes
    const matchAttributes = (attributes: any, desiredAttributes: any) => {
      return Object.keys(desiredAttributes).every((key) =>
        attributes.some(
          (attr: any) =>
            attr.name === key && attr.option === desiredAttributes[key]
        )
      );
    };

    // Find the variation that matches the desired attributes
    const findMatchingVariation = (desiredAttributes: any) => {
      return varient.find((variation: any) =>
        matchAttributes(variation.attributes, desiredAttributes)
      );
    };

    const result = findMatchingVariation(obj);
    // console.log(result, "<<-- result");
    return result;
  }
}
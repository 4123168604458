import React, { useState, useRef, useEffect } from 'react';

interface ProductDescriptionProps {
  description: string;
  initialHeight?: number;
  showMoreText?: string;
  showLessText?: string;
}

const ProductDescription: React.FC<ProductDescriptionProps> = ({
  description,
  initialHeight = 300,
  showMoreText = 'Show more',
  showLessText = 'Show less',
}) => {
  const [expanded, setExpanded] = useState(false);
  const [hasOverflow, setHasOverflow] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      const hasContentOverflow = contentRef.current.scrollHeight > initialHeight;
      setHasOverflow(hasContentOverflow);
    }
  }, [description, initialHeight]);

  // Function to sanitize HTML and reduce DOM elements
  const optimizeHtml = (html: string) => {
    // Remove empty paragraphs, excessive divs, etc.
    let optimized = html
      .replace(/<p>\s*<\/p>/g, '') // Remove empty paragraphs
      .replace(/<div>\s*<\/div>/g, '') // Remove empty divs
      .replace(/(<br\s*\/?>){3,}/g, '<br /><br />'); // Reduce multiple line breaks

    return optimized;
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="product-description-container mt-4 mb-6 min-h-[100px]">
      <div
        ref={contentRef}
        className="pro-details transition-all duration-300"
        style={{
          maxHeight: expanded ? '100%' : `${initialHeight}px`,
          overflow: expanded ? 'visible' : 'hidden',
          position: 'relative',
        }}
        dangerouslySetInnerHTML={{
          __html: optimizeHtml(description),
        }}
      />

      {hasOverflow && (
        <button
          onClick={toggleExpand}
          className="text-primary font-medium mt-2 flex items-center"
        >
          {expanded ? showLessText : showMoreText}
          <svg
            className={`ml-1 transform ${expanded ? 'rotate-180' : ''} transition-transform duration-300`}
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1.5L6 6.5L11 1.5"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      )}
    </div>
  );
};

export default ProductDescription;
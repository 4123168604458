import React from 'react'
import { Lock, PPolicy, SeaSame, Shipping } from '../common/Svg'
import { capitalizeFirstLetter } from '../../utils/helper'
import { uniqueId } from 'lodash'
import { navigate } from 'gatsby'
import Varient from './Varient'
import ProductDetailsCounter from '../common/ProductDetailsCounter'
import visa from '../../../src/assets/images/visa-card.png';
import vimeo from '../../../src/assets/images/vimeo.png';
import masterCard from '../../../src/assets/images/master-card.png';

export const MobilePro = (props:any) => {
  const {
    varButtonRef,data,product, groupedProduct,productPrice , productPriceLimit,selectedOption,handleVariantChange, selectedProductPrice, selectedOptions,handleOptionChange,variants, selectedVariant, handleSelect, setInputValue, inputValue, block,handleAddToCart,
} = props.props
  return (
    <div
className="w-full var-button lg:pb-0 left-0 pb-[10px] bg-white z-30 px-3 dropshadow-cust"
ref={varButtonRef}
>

{variants?.length > 0 ? (
  <div className="md:py-4 pt-6 overflow-x-auto w-full md:min-h-[194px]">
    <Varient
      varients={variants}
      selectedVarient={selectedVariant}
      handleSelect={handleSelect}
    />
  </div>
) : (
  <div className="md:min-h-[194px]"></div>
)}

{(variants.length > 0 ||
  groupedProduct.length > 0 ||
  product.type === 'simple') && (
    <div className="mt-1 relative flex items-center justify-between gap-2 lg:min-h-[54px]">
      <div className="w-[30%] md:block hidden">
        <ProductDetailsCounter
          initialQuantity={1}
          setInputValue={setInputValue}
          inputValue={inputValue}
        />
      </div>
      <div className="lg:w-[70%] md:w-[60%] w-full">
        <button
          className="w-full md:h-[54px] h-[40px] bg-[#000000] hover:bg-primary text-white font-bold py-2 px-4 relative rounded-full transition-all ease-in disabled:bg-gray-400 disabled:cursor-not-allowed"
          disabled={
            block ||
            props?.data?.data?.stock_status === 'outofstock'
          }
          onClick={() =>
            product?.button_text
              ? window.open(
                product.external_url,
                '_blank',
                'noopener,noreferrer'
              )
              : handleAddToCart (product, selectedVariant)
          }
        >
          {product?.button_text ? (
            <div className="lg:text-[20px] md:text-[18px] text-white font-sofiaRegular">
              {product.button_text}
            </div>
          ) : groupedProduct?.length > 0 ? (
            <div className="lg:text-[20px] md:text-[18px] text-white font-sofiaRegular">
              {block ? 'Adding...' : 'Add to cart'}
            </div>
          ) : (
            <div className="flex justify-between items-center">
              {block ? (
                <div className="lg:text-[20px] md:text-[18px] text-white font-sofiaRegular">
                  Adding...
                </div>
              ) : (
                <>
                  <div className="lg:text-[15px] xl:text-[20px] text-white font-sofiaRegular">
                    Add to cart
                  </div>
                  <div className="lg:text-[15px] xl:text-[20px] text-white font-sofiaRegular">
                    $
                    {productPrice
                      ? Number(productPrice).toFixed(2)
                      : Number(product?.price).toFixed(2) ||
                      '0.00'}
                  </div>
                </>
              )}
            </div>
          )}
        </button>
      </div>
    </div>
  )}

<div className="flex payment-card mt-2 justify-center min-h-[38px]">
  <ul className="flex gap-2">
    {[visa, vimeo, masterCard].map((src, index) => (
      <li key={index} className="w-[38px] h-[38px] border">
        <img
          src={src}
          alt="payment card"
          className="w-full contain"
          style={{
            cursor: 'pointer',
            height: '100%',
            width: '100%',
            objectFit: 'contain',
          }}
          width={38}
          height={38}
          loading="lazy"
        />
      </li>
    ))}
    <li className="flex items-center font-sofiaRegular font-medium md:text-[14px] text-[13px]">
      <span className="w-[20px] h-[20px]">
        <Lock />
      </span>
      Secure Checkout
    </li>
  </ul>
</div>

<div className="bg-[#F5F5F5] md:px-[8px] px-[15px] py-[10px] lg:rounded-[36px] rounded-[14px] lg:mt-[15px] mt-[10px] md:block hidden min-h-[60px]">
  <ul className="flex md:flex-row flex-col md:justify-center justify-start md:items-center gap-1 trans-details">
    <li>
      <span className="w-30px">
        <Shipping />
      </span>
      <span className="!w-full">
        Free shipping over $100
      </span>
    </li>
    <li>
      <span className="w-30px">
        <SeaSame />
      </span>
      <span className="!w-full">
        US compounding Pharmacy
      </span>
    </li>
    <li>
      <span className="w-30px">
        <PPolicy />
      </span>
      <span className="!w-full">Privacy Guarantee</span>
    </li>
  </ul>
</div>
</div>
  )
}

import React, { useRef, useEffect, useState, FC } from 'react';

type Props = {
  html: TrustedHTML;
  showReadMore?: boolean;
  wordCount?: number;
  removeHeading?: boolean;
  className?: string;
};

const TruncatedHtml: FC<Props> = ({
  html,
  showReadMore = true,
  wordCount = 108,
  removeHeading = false,
  className = '',
}) => {
  const [showAll, setShowAll] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  let content = html?.toString();
  if (removeHeading) {
    content = content
      .replace(/<h2>.*?<\/h2>\s*/g, '')
      .replace(/<p>\s*&nbsp;\s*<\/p>/g, ''); // Remove &nbsp; inside <p> tags
  }
  const truncatedContent = content?.substring(0, wordCount) + '...';


  useEffect(() => {
    if (contentRef.current) {
      const displayContent =
        !showAll && content?.length > wordCount ? truncatedContent : content;
      const toggleText = showAll ? ' read less' : ' read more';

      contentRef.current.innerHTML = `
                <div class="pro-det-text lg:block hidden relative ${className}">
                    ${displayContent}
                    ${
                      content?.length > wordCount && showReadMore
                        ? `<span class="cursor-pointer font-sofiaMedium font-medium my-1 text-primary  bg-white">${toggleText}</span>`
                        : ''
                    }
                </div>
            `;

      // Add click handler if toggleSpan exists
      const toggleSpan = contentRef.current.querySelector('span');
      if (toggleSpan) {
        toggleSpan.onclick = () => setShowAll(!showAll);
      }
    }
  }, [showAll, content, truncatedContent]);

  return <div ref={contentRef}></div>;
};

export default TruncatedHtml;

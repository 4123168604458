import React, { FC, useEffect, useState } from 'react';
import Button from '../common/Buttons/Button';

interface Props {
  varients: any;
  selectedVarient: any;
  handleSelect: Function;
}

const Varient: FC<Props> = ({ varients, selectedVarient, handleSelect }) => {
  const [discounts, setDiscounts] = useState<{ [key: number]: string }>({});

  useEffect(() => {
    const calculateAllDiscounts = async () => {
      const discountObj: { [key: number]: string } = {};
      for (const val of varients) {
        const discount = await calculateDiscount(
          val.regular_price,
          val.sale_price
        );
        discountObj[val.id] = discount;
      }
      setDiscounts(discountObj);
    };

    calculateAllDiscounts();
  }, [varients]);

  return (
    <div className="flex justify-start gap-6 pl-3 min-w-max">
      {varients.map((val: any) => {
        return (
          <div
            key={val.id}
            className={`col cursor-pointer xl:w-[160px] lg:w-[110px] w-[90px] rounded-4  relative`}
            onClick={() => handleSelect(val)}
          >
            <div className="card-sm relative">
              <div
                className={` md:w-[35px] w-[23px] md:h-[35px] h-[23px] absolute top-[-10px] left-[-10px] flex justify-center items-center text-[14px] rounded-full text-white font-sofiaSemibold font-semiBold ${
                  selectedVarient?.id === val.id ? 'bg-primary' : 'bg-[#81878F]'
                }`}
              >
                {val.name.split(',')[1]}
              </div>
              <img
                src={
                  val?.image?.src
                    ? val.image.src
                    : `${process.env.GATSBY_SITE_URL}/wp-content/uploads/2024/08/no-image-sq.png`
                }
                className={` bg-[#ffffff] object-contain xl:w-[160px] lg:w-[110px] w-[90px] xl:h-[120px] lg:h-[100px] h-[78px]  border-2  md:rounded-[21px] rounded-[12px] ${
                  selectedVarient?.id === val.id
                    ? 'border-primary'
                    : 'border-[#E5E5E5]'
                }`}
                alt={val?.image?.alt}
              />
              <div className="text-center mt-1">
                {
                  val.on_sale ? (
                    // ON SALE START
                    <h2
                      className={` font-sofiaBold font-bold md:text-[20px] text-[14px] leading-[1.2] lg: min-h-[18px] ${
                        selectedVarient?.id === val.id
                          ? 'text-primary'
                          : 'text-black'
                      }`}
                    >
                      ${Number(val.sale_price).toFixed(2)}
                    </h2>
                  ) : (
                    // ON SALE END
                    // NOT ON SALE START
                    <h2
                      className={` font-sofiaBold font-bold md:text-[20px] text-[14px] leading-[1.2] min-h-[18px] ${
                        selectedVarient?.id === val.id
                          ? 'text-primary'
                          : 'text-black'
                      }`}
                    >
                      ${Number(val.price).toFixed(2)}
                    </h2>
                  )
                  // NOT ON SALE END
                }

                {val.on_sale && (
                  <div className="flex gap-2 justify-center items-center">
                    <del className=" font-sofiaBold font-bold md:text-[16px] text-[#383838] md:block hidden">
                      ${val.regular_price}
                    </del>
                    <Button
                      type={'button'}
                      className={`btn !w-[30px] !h-[29px] !py-0 !p-0 text-white rounded-[21px] btn-sm rounded-5 font-sofiaBold font-regular xl:text-[12px] text-[12px] flex justify-center items-center absolute md:relative md:top-0 md:right-0 top-[-13px] right-[-10px]  ${
                        selectedVarient?.id === val.id
                          ? 'bg-primary'
                          : 'bg-[#81878F]'
                      }`}
                      text={discounts[val.id] || 'Calculating...'}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Varient;

async function calculateDiscount(regularPrice: any, salePrice: any) {
  const regularPriceNum = Number(regularPrice);
  const salePriceNum = Number(salePrice);

  if (
    regularPriceNum <= 0 ||
    salePriceNum < 0 ||
    salePriceNum > regularPriceNum
  ) {
    return 'Invalid prices';
  }

  const discount = ((regularPriceNum - salePriceNum) / regularPriceNum) * 100;
  return `${discount.toFixed()}%`;
}
import React from 'react'
import { Lock, PPolicy, SeaSame, Shipping } from '../common/Svg'
import { capitalizeFirstLetter } from '../../utils/helper'
import { uniqueId } from 'lodash'
import { navigate } from 'gatsby'
import Varient from './Varient'
import ProductDetailsCounter from '../common/ProductDetailsCounter'
import visa from '../../../src/assets/images/visa-card.png';
import vimeo from '../../../src/assets/images/vimeo.png';
import masterCard from '../../../src/assets/images/master-card.png';

export const DesktopPro = (props:any) => {
    const {
      varButtonRef,data,product, groupedProduct,productPrice , productPriceLimit,selectedOption,handleVariantChange, selectedProductPrice, selectedOptions,handleOptionChange,variants, selectedVariant, handleSelect, setInputValue, inputValue, block,handleAddToCart,
  } = props.props
    return (
      <div
  className="w-full var-button lg:pb-0 left-0 pb-[10px] bg-white z-30 px-3 dropshadow-cust"
  ref={varButtonRef}
  >
  {data.data?.price && (
    <div className="mb-1 lg:block hidden min-h-[25px]">
      <span className="font-sofiaSemibold font-semiBold text-primary xl:text-[25px] text-[20px] min-h-[25px]">
        {groupedProduct?.length > 0
          ? `$${productPrice}`
          : data.data.type === 'variable'
            ? productPriceLimit
            : `$${Number(
              data.data?.sale_price || data.data.price
            ).toFixed(2)}`}
      </span>
    </div>
  )}
  
  {product?.attributes?.[0]?.options.length > 1 && (
    <div className="min-h-[40px] mb-[10px] lg:block hidden mt-[5px]">
      <span className="mr-3 font-sofiaBold font-bold capitalize text-[16px]">
        {product?.attributes?.[0].name}:
      </span>
      <select
        value={selectedOption}
        onChange={handleVariantChange}
        id="sort-direction-desktop"
        className="bg-bg-grey h-[40px] rounded-[12px] text-[14px] px-3 font-sofiaRegular focus:outline-none border md:mt-0 mt-5 w-[160px]"
      >
        {product?.attributes?.[0]?.options?.map((option) => (
          <option value={option} key={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  )}
  
  {product?.attributes?.[0]?.options.length === 1 && (
    <div className="mt-[5px] mb-[10px] lg:block hidden min-h-[24px]">
      <span className="mr-1 capitalize font-sofiaBold font-bold text-[16px]">
        {product?.attributes?.[0].name}:
      </span>
      <span className="font-sofiaMedium text-[16px]">
        {product?.attributes?.[0]?.options[0]}
      </span>
    </div>
  )}
  
  {groupedProduct?.length > 0 && (
    <div className="lg:block hidden bg-bg-grey px-4 py-2 rounded-[12px] h-[210px] overflow-y-auto">
      <div className="h-full">
        {groupedProduct.map((grpProd) => (
          <div
            key={grpProd.id}
            className="flex justify-between items-center cursor-pointer border-b last:border-b-0 pb-2 last:pb-0 mb-2 last:mb-0"
          >
            <div className="flex items-center gap-6 w-full">
              <div
                className="w-[50px] h-[50px] bg-white p-2 flex items-center rounded-[9px]"
                onClick={() =>
                  navigate(`/products/${grpProd.slug}`, {
                    state: {
                      ...props.location.state,
                      productName: grpProd.name,
                      productId: grpProd.id,
                    },
                  })
                }
              >
                <img
                  src={grpProd.images[0].src}
                  alt={grpProd.images[0].alt}
                  width={50}
                  height={50}
                  className="rounded object-contain w-[50px] h-[50px]"
                  loading="lazy"
                />
              </div>
              <div className="w-full">
                <div
                  className="flex justify-between items-center w-full"
                  onClick={() =>
                    navigate(`/products/${grpProd.slug}`, {
                      state: {
                        ...props.location.state,
                        productName: grpProd.name,
                        productId: grpProd.id,
                      },
                    })
                  }
                >
                  <p className="font-sofiaRegular xl:text-[15px] text-[13px]">
                    {grpProd.name}
                  </p>
                  <p className="font-sofiaRegular xl:text-[15px] text-[13px]">
                    {selectedProductPrice[grpProd.id]?.price
                      ? `$${Number(
                        selectedProductPrice[grpProd.id]
                          .price
                      ).toFixed(2)}`
                      : `$${Number(
                        grpProd.varient?.[0].sale_price
                      ).toFixed(2)}`}
                  </p>
                </div>
                <div className="flex items-center gap-4">
                  {grpProd?.attributes?.map(
                    (attribute, index) => (
                      <div key={index}>
                        <span className="mr-3 font-sofiaRegular xl:text-[15px] text-[13px]">
                          {capitalizeFirstLetter(
                            attribute?.name
                          )}
                          :
                        </span>
                        <select
                          id={`${uniqueId()}-select`}
                          value={
                            selectedOptions?.[grpProd.id]?.[
                            attribute.name
                            ] || ''
                          }
                          onChange={(e) =>
                            handleOptionChange(
                              grpProd,
                              attribute.name,
                              e.target.value
                            )
                          }
                          className="w-fit bg-bg-grey h-[34px] rounded-[3px] xl:text-[15px] text-[13px] px-1 font-sofiaRegular focus:outline-none border md:mt-0 mt-5"
                        >
                          {attribute?.options?.map(
                            (option) => (
                              <option
                                value={option}
                                key={option}
                              >
                                {option}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )}
  
  {variants?.length > 0 ? (
    <div className="md:py-4 pt-6 overflow-x-auto w-full md:min-h-[194px]">
      <Varient
        varients={variants}
        selectedVarient={selectedVariant}
        handleSelect={handleSelect}
      />
    </div>
  ) : (
    <div className="md:min-h-[194px]"></div>
  )}
  
  {(variants.length > 0 ||
    groupedProduct.length > 0 ||
    product.type === 'simple') && (
      <div className="mt-1 relative flex items-center justify-between gap-2 lg:min-h-[54px]">
        <div className="w-[30%] md:block hidden">
          <ProductDetailsCounter
            initialQuantity={1}
            setInputValue={setInputValue}
            inputValue={inputValue}
          />
        </div>
        <div className="lg:w-[70%] md:w-[60%] w-full">
          <button
            className="w-full md:h-[54px] h-[40px] bg-[#000000] hover:bg-primary text-white font-bold py-2 px-4 relative rounded-full transition-all ease-in disabled:bg-gray-400 disabled:cursor-not-allowed"
            disabled={
              block ||
              props?.data?.data?.stock_status === 'outofstock'
            }
            onClick={() =>
              product?.button_text
                ? window.open(
                  product.external_url,
                  '_blank',
                  'noopener,noreferrer'
                )
                : handleAddToCart (product, selectedVariant)
            }
          >
            {product?.button_text ? (
              <div className="lg:text-[20px] md:text-[18px] text-white font-sofiaRegular">
                {product.button_text}
              </div>
            ) : groupedProduct?.length > 0 ? (
              <div className="lg:text-[20px] md:text-[18px] text-white font-sofiaRegular">
                {block ? 'Adding...' : 'Add to cart'}
              </div>
            ) : (
              <div className="flex justify-between items-center">
                {block ? (
                  <div className="lg:text-[20px] md:text-[18px] text-white font-sofiaRegular">
                    Adding...
                  </div>
                ) : (
                  <>
                    <div className="lg:text-[15px] xl:text-[20px] text-white font-sofiaRegular">
                      Add to cart
                    </div>
                    <div className="lg:text-[15px] xl:text-[20px] text-white font-sofiaRegular">
                      $
                      {productPrice
                        ? Number(productPrice).toFixed(2)
                        : Number(product?.price).toFixed(2) ||
                        '0.00'}
                    </div>
                  </>
                )}
              </div>
            )}
          </button>
        </div>
      </div>
    )}
  
  <div className="flex payment-card mt-2 justify-center min-h-[38px]">
    <ul className="flex gap-2">
      {[visa, vimeo, masterCard].map((src, index) => (
        <li key={index} className="w-[38px] h-[38px] border">
          <img
            src={src}
            alt="payment card"
            className="w-full contain"
            style={{
              cursor: 'pointer',
              height: '100%',
              width: '100%',
              objectFit: 'contain',
            }}
            width={38}
            height={38}
            loading="lazy"
          />
        </li>
      ))}
      <li className="flex items-center font-sofiaRegular font-medium md:text-[14px] text-[13px]">
        <span className="w-[20px] h-[20px]">
          <Lock />
        </span>
        Secure Checkout
      </li>
    </ul>
  </div>
  
  <div className="bg-[#F5F5F5] md:px-[8px] px-[15px] py-[10px] lg:rounded-[36px] rounded-[14px] lg:mt-[15px] mt-[10px] ">
    <ul className="flex md:flex-row flex-col md:justify-center justify-start md:items-center gap-1 trans-details">
      <li>
        <span className="w-30px">
          <Shipping />
        </span>
        <span className="!w-full">
          Free shipping over $100
        </span>
      </li>
      <li>
        <span className="w-30px">
          <SeaSame />
        </span>
        <span className="!w-full">
          US compounding Pharmacy
        </span>
      </li>
      <li>
        <span className="w-30px">
          <PPolicy />
        </span>
        <span className="!w-full">Privacy Guarantee</span>
      </li>
    </ul>
  </div>
  </div>
  )
}
